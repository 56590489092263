<template>
  <div>
    <ManageIdentity />
  </div>
</template>
<script>
import ManageIdentity from "@/components/ManageIdentity/ManageIdentity";
export default {
  components: {
    ManageIdentity,
  },
  created() {},
};
</script>
